import React, { useState } from "react"
import { Link} from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import logo from "../../content/assets/logo.svg"
import Pin from "./pin"
import menubars from "../../content/assets/menu-bars.svg"



export default () => {
  const data = useStaticQuery(graphql`
    query NavQuery {
    site {
      siteMetadata {
        title
      }
    }

  }
  `)

  const siteTitle = data.site.siteMetadata.title

  const [isMenu, setIsMenu] = useState('');
  const toggleList = () => {
    setIsMenu( !isMenu ? 'db animated fastest slideInRight fixed z-999 top-5 left-0 w-100 vh-100  ' : '' );
  }




  return (
    <div>

      <Pin>
          <div> 
            <div className="w-100  ">
              <div className="ph3 ph4-ns pv1">
                <div className="mw9 center">
                    <nav className="flex justify-between pv2">

                      <Link className="logo-font f5 f5-ns fw5 ttu link dim theme-100  dib mr3 flex items-center tracked" to="/" >
                        <img className="square-3 mr3" src={logo} alt='south african sugar industry logo'/>
                        {siteTitle}
                      </Link>

                      <div className="cf">
                      <div className="db dn-ns pv2 fr" >  <img onMouseUp = {toggleList}  className="w3 h2" src={menubars} alt='menu'/> </div>

                        <div  className='dn db-ns pv2 lh-copy '>
                          <div className="flex flex-wrap pv2 tr">
                                                             
                            <Link className="f6 fw3 ttu link dim theme-100 mr3 tracked tr" to="/category/#sa sugar industry" > overview </Link>
                            <Link className="f6 fw3 ttu link dim theme-100 mr3 tracked tr" to="/category/#cane growing in south africa" > growing </Link>
                            <Link className="f6 fw3 ttu link dim theme-100 mr3 tracked tr" to="/category/#milling and refining" > milling </Link>
                            <Link className="f6 fw3 ttu link dim theme-100 mr3 tracked tr" to="/category/#sasa" > sasa </Link>
                            <Link className="f6 fw3 ttu link dim theme-100 mr3 tracked tr nowrap" to="/factsandfigures" > facts and figures </Link>
                            <Link className="f6 fw3 ttu link dim theme-100 mr3 tracked tr" to="/directory" > directory </Link> 
                            <Link className="f6 fw3 ttu link dim theme-100 mr3 tracked tr" to="/history" > history </Link> 

                          </div>
                        </div>


                        <div  className={'dn pv2 bg-theme-menu ' + isMenu }>
                          <div className="flex flex-column pv4 ph3">
                                                             
                            <Link onMouseUp = {toggleList} className="f4 fw4 ttu lh-1-6 link dim theme-0 mb1 tracked pv2 bb b--white" to="/category/#sa sugar industry" > SA Sugar Industry </Link>
                            <Link onMouseUp = {toggleList} className="f4 fw4 ttu lh-1-6 link dim theme-0 mb1 tracked pv2 bb b--white" to="/#locations" > Operating locations </Link> 
                            <Link onMouseUp = {toggleList} className="f4 fw4 ttu lh-1-6 link dim theme-0 mb1 tracked pv2 bb b--white" to="/category/#cane growing in south africa" > Cane growing in SA </Link>
                            <Link onMouseUp = {toggleList} className="f4 fw4 ttu lh-1-6 link dim theme-0 mb1 tracked pv2 bb b--white" to="/category/#milling and refining" > Milling and Refining in SA </Link>
                            <Link onMouseUp = {toggleList} className="f4 fw4 ttu lh-1-6 link dim theme-0 mb1 tracked pv2 bb b--white" to="/category/#sasa" > South African Sugar Association </Link>
                            <Link onMouseUp = {toggleList} className="f4 fw4 ttu lh-1-6 link dim theme-0 mb1 tracked pv2 bb b--white" to="/factsandfigures" > Industry facts and figures </Link>
                            <Link onMouseUp = {toggleList} className="f4 fw4 ttu lh-1-6 link dim theme-0 mb1 tracked pv2 bb b--white" to="/directory" > Directory </Link> 
                            <Link onMouseUp = {toggleList} className="f4 fw4 ttu lh-1-6 link dim theme-0 mb1 tracked pv2 bb b--white" to="/history" > Historical highlights </Link> 

                          </div>
                        </div>



                      </div>


                     
                    </nav>
                </div>
              </div>
            </div>
          </div>
      </Pin>

      </div>
  )
}
