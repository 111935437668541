import React from "react"
import logo from "../../content/assets/logo.svg"
import pdfwhite from "../../content/assets/pdf-white.svg"
import { useStaticQuery, graphql } from "gatsby"


export default () => {

  const data = useStaticQuery(graphql`
    query PdfQuery {
      allFile(filter: { extension: { eq: "pdf" } }) {
        edges {
          node {
            id
            publicURL
          }
        }
      }
  }
  `)

const pdf = data.allFile.edges

return (
<footer className="ph0 ph4-m ph5-l fw2 theme-0-30 bg-theme-100  pt5 pb3" >

<div className="w-100 cf">
<div className="ph4 pv1">
<div className="mw9 center cf">

    <div className="fl w-100 pv0 ttu theme-0 tracked-mega">
      <div className="flex flex-wrap items-center justify-between mb4">

            <div>
              <div className="mr2">
                <img src={logo} className="square-3 dib mr3" alt="logo" />
              </div>
              <div className="flex flex-column">
                <div className="f7 f6-m f5-l fw5 theme-0 mb2 lh-copy tracked-mega">South African Sugar Industry </div>
                <div className="f7 fw5 theme-0-10 tracked-mega">Directory 2019</div> 
              </div>
            </div>

          
          </div>
       </div>

    <section className="fl w-100 w-50-ns dib-ns w-auto-ns mr4-m mr5-l mb4 pr2 pr0-ns tracked-mega lh-1-3">
      <h4 className="f5  fw2 theme-0-20 tracked-mega">Kwa-Shukela</h4>
      
      <span className="f6 db">170 Flanders Drive</span>
      <span className="f6 db">Mount Edgecombe</span>
      <span className="f6 db">Durban </span>
      <span className="f6 db">KwaZulu-Natal </span>
      <span className="f6 db">South Africa </span>
      <a className="f6 db fw5 pt3 theme-0-20  dim tracked-lg" title="Call SF" href="tel:+27315087000">
        +27 0 31 500 7000
      </a>
      <a className="f6 db fw5 pv3 theme-0-20  dim tracked-lg"  href="http://www.sasa.org.za">
        www.sasa.org.za
      </a>
    </section>

    <section className="cf mb2 bg-theme-100"></section>

    {pdf.map(({ node }) => {
              return (  <a key={node.id} className="pt5 pt0-ns dim" href={node.publicURL} rel="noopener noreferrer" target="_blank"> 
                        <img className="mw3 o-40" src={pdfwhite} alt="south african sugar industry directory pdf" />
                          <div className="f7 lh-copy fw4 white-50 ttc mw5 ttu tl tracked-lg pl3">Download SASID 2019/20</div>
                      </a>)
    })}


  </div>

 

<section className="cf mb5 bg-theme-100 pl4"></section>

  <div className="w-100 mw9 center">
    
      <div className="f7 pr3 mb3 tracked-lg lh-1-3">
        Copyright © SASA/ <a className="theme-0-30" href="http://www.lightship.co.za">Lightship Studios – {new Date().getFullYear()}</a>, Built with React.js & Gatsby.js 
      </div>
   

 


  </div>
  </div>
  </div>
</footer>

)

}
